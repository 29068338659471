import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import Layout from "../components/layout"
import Seo from "../components/seo"

const LegalNotice = () => {
  const AniBgColor =
    typeof window !== `undefined`
      ? window.matchMedia("(prefers-color-scheme: dark)").matches
        ? "#ecfd08"
        : "#1707fd"
      : null

  return (
    <Layout>
      <Seo title="Dirk Schmid - Frontend Developer with UX Design background" />
      <AniLink
        cover
        bg={AniBgColor}
        to="/"
        duration={0.7}
        className="back-link"
      >
        <span>&#9650;</span> Back
      </AniLink>
      <h2 className="legal-heading">Legal notice</h2>
      <p>Responsible for the content of the internet pages: </p>
      <p>
        Dirk Schmid
        <br />
        Georg-Löwenstein-Str. 38
        <br />
        10317 Berlin
      </p>

      <p>
        Mail: mail@dirkschmid.de
        <br />
        Phone: +49 176 24 24 35 96
      </p>
      <p>
        I assume no liability for the content of external links. This website
        does not use tracking tools and does not set cookies.
      </p>
    </Layout>
  )
}

export default LegalNotice
